import request from "superagent";
import config from "../config";

export async function getDistanceFromPoint(origin, points) {
  try {
    var res = await request
      .post(config.routeServiceUrl + "/DistancesToPoint")
      //   .post("https://localhost:44349/DistancesToPoint")
      .send({ origin, points })
      .set("Content-Type", "application/json");
    return res.body;
  } catch (ex) {
    console.error("DistancesToPoint error:", ex);
    return null;
  }
}
