import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { VectorSource, provideMapState } from "@avinet/react-openlayers";
import Collapsible from "../../component/Collapsible";
import { getDistance } from "ol/sphere";
import { buffer } from "ol/extent";
import GeometryCollection from "ol/geom/GeometryCollection";
import Loading from "../../component/Loading/Loading";
import config from "../../config";
import bilbatvrak from "../../static/baatvrakwback.svg";
import { Style, Icon } from "ol/style";
import "./Gjenvinningsstasjon.scss";

const mapStateToProps = state => ({
  records: state.gjenvinningsstasjoner.records,
  loading: state.gjenvinningsstasjoner.loading
});

class BilBatvrak extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closest: undefined
    };
  }

  static propTypes = {
    address: PropTypes.object.isRequired,
    records: PropTypes.array.isRequired,
    loading: PropTypes.bool
  };

  static getDerivedStateFromProps(props, state) {
    if (props.records.length > 0 && !state.closest) {
      let closest = undefined;
      const ap = props.address.olFeature
        .getGeometry()
        .clone()
        .transform(config.mapProjection, "EPSG:4326");
      props.records.forEach(r => {
        if (!r.bilbatvrak) {
          return;
        }

        const p = r.olFeature
          .getGeometry()
          .clone()
          .transform(config.mapProjection, "EPSG:4326");
        const newDist = getDistance(p.getCoordinates(), ap.getCoordinates());

        if (!closest || newDist < closest.distance) {
          r.distance = newDist;
          closest = r;
        }
      });
      state.closest = closest;
    }

    if (props.loading) {
      state.closest = undefined;
    }
    return state;
  }

  onExpand = () => {
    const { fitViewAndZoom } = this.props;
    const { closest } = this.state;
    if (closest && closest.id) {
      var extent = buffer(
        new GeometryCollection([
          this.state.closest.olFeature.getGeometry(),
          this.props.address.olFeature.getGeometry()
        ]).getExtent(),
        1.2
      );
      fitViewAndZoom(extent, config.fitViewAndZoom.in);
    }
  };

  onCollapse = () => {
    const { fitViewAndZoom } = this.props;
    fitViewAndZoom(
      this.props.address.olFeature.getGeometry(),
      config.fitViewAndZoom.out
    );
  };

  render() {
    const { t, loading } = this.props;
    const { closest } = this.state;
    return (
      <Collapsible
        className="hushald"
        header={
          loading ? (
            <React.Fragment>
              {t("closest")}
              {loading && <Loading />}{" "}
            </React.Fragment>
          ) : (
            t("closest")
          )
        }
        onCollapse={this.onCollapse}
        onExpand={this.onExpand}
        toggle={
          <VectorSource
            features={closest ? [closest.olFeature] : []}
            layerName="bilbatvrak-layer"
            key="bilbatvrak-layer"
            selectable={false}
            styleFn={() =>
              new Style({
                image: new Icon({
                  src: bilbatvrak,
                  scale: 1.4
                })
              })
            }
          />
        }
      >
        <div className="gjenvinningsstasjon--content">
          {closest ? (
            <div className="gjenvinningsstasjon--hours">
              <h3>{closest.navn}</h3>
              <p>
                <span>{t("monday")} </span>
                <span>{closest.mandag}</span>
              </p>
              <p>
                <span>{t("tuesday")} </span>
                <span>{closest.tirsdag}</span>
              </p>
              <p>
                <span>{t("wednesday")} </span>
                <span>{closest.onsdag}</span>
              </p>
              <p>
                <span>{t("thursday")}</span>
                <span>{closest.torsdag}</span>
              </p>
              <p>
                <span>{t("friday")} </span>
                <span>{closest.fredag}</span>
              </p>
              <p>
                <span>{t("saturday")} </span>
                <span>{closest.lordag}</span>
              </p>
              {closest.unntak && closest.unntak.length && (
                <div className="holidays">
                  <h4>{t("unntak")}</h4>
                  {closest.unntak.split(/\n/g).map((u, i) =>
                    u.length < 1 ? (
                      ""
                    ) : (
                      <p key={i}>
                        {u}
                        <br />
                      </p>
                    )
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="gjenvinningsstasjon--no-results">
              {t("noResults")}
            </div>
          )}
          <p>
            Sjå meir informasjon om <Link to="/bilvrak">bil-</Link> og{" "}
            <Link to="/batvrak">båtvrak</Link>.
          </p>
        </div>
      </Collapsible>
    );
  }
}

export default connect(
  mapStateToProps,
  undefined
)(translate("BilBatvrak")(provideMapState(BilBatvrak)));
