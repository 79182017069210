import React from "react";
import { Link } from "react-router-dom";
import config from "../../config/index";

const Nettstedkart = () => {
  const { hushald, hytte, avvik, naering, navigation } = config.nav;
  console.log({ hushald, hytte, avvik, naering, navigation });
  return (
    <div>
      <h1>Nettstedkart</h1>
      <ul>
        {navigation.questions.map(entry => {
          return (
            <li>
              <Link key={entry.uri} to={entry.uri}>
                {entry.title}
              </Link>
            </li>
          );
        })}
        {navigation.services.map(entry => {
          return (
            <li>
              <Link key={entry.uri} to={entry.uri}>
                {entry.title}
              </Link>
            </li>
          );
        })}
        {navigation.us.map(entry => {
          return (
            <li>
              <Link key={entry.uri} to={entry.uri}>
                {entry.title}
              </Link>
            </li>
          );
        })}
        {naering.links.map(entry => {
          return (
            <li>
              <Link key={entry.uri} to={entry.uri}>
                {entry.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Nettstedkart;
