import React from "react";
import PropTypes from "prop-types";
import Title from "../component/Title";
import sanitizeHtml from "sanitize-html";
import Page404 from "../component/Page404";
import Loading from "../component/Loading/Loading";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { translate } from "react-translate";
import { withRouter } from "react-router-dom";
import { pageActions } from "react-adaptive-cms";
import { fixLinks, getPath, checkDomainAndPathName } from "../utils/Path";

import "./WiderPageView.scss";

const mapStateToProps = state => ({
  page: state.page.page,
  pageLoading: state.page.pageLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(Object.assign({}, pageActions), dispatch);

class WiderPageView extends React.Component {
  componentDidMount() {
    const { loadPage, location } = this.props;
    const path = getPath(location);

    loadPage(path);
    if (location.hash) {
      const elem = document.getElementById(location.hash);
      if (elem) {
        elem.setAttribute("open", true);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { history, location, pageLoading } = this.props;
    const prevPath = getPath(prevProps.location);
    const path = getPath(location);
    fixLinks(history);
    if (path !== prevPath) {
      this.props.loadPage(path);
    }

    if (prevProps.pageLoading && !pageLoading && location.hash) {
      const elem = document.getElementById(location.hash.replace("/", ""));
      if (elem && !elem.open) {
        elem.setAttribute("open", true);
        elem.scrollIntoView();
        window.scrollBy(0, -100);
      }
    }

    var summaryTags = document.querySelectorAll("details");
    summaryTags.forEach(
      f =>
        (f.onclick = () => {
          this.onSummaryClick(f);
        })
    );
  }

  onSummaryClick = item => {
    const {
      history,
      location: { pathname }
    } = this.props;

    if (item.id) {
      const hash = item.open ? "" : item.id;
      history.push(
        pathname +
          (pathname === "/gjenvinningsstasjonar" ||
          pathname === "/slik-sorterer-du"
            ? "/"
            : "#") +
          hash
      );
    }
    return true;
  };

  render() {
    const {
      page: { is404, content, title },
      pageLoading,
      history
    } = this.props;
    console.log("WIDER");
    return (
      <div className="wider-page-view--page">
        {content && (
          <Helmet>
            <meta
              name="description"
              content={content.replace(/<[^>]*>|\\n/g, "").slice(0, 150)}
            />
          </Helmet>
        )}
        <Title title={is404 ? "Page not found" : title || "Loading"} />
        {pageLoading ? (
          <div className="loader">
            <Loading />
          </div>
        ) : is404 ? (
          <Page404 />
        ) : (
          <div
            className="wider-page-view--cms"
            onClick={evt => {
              // capture clicks on links to current domain in order to use react router instead of full pageload.
              const targetLink = evt.target.closest("a");
              if (targetLink) {
                console.log("targetlink", targetLink);
                const { isCurrentDomain, pathname } = checkDomainAndPathName(
                  targetLink.href
                );
                if (isCurrentDomain) {
                  evt.preventDefault();
                  history.push(pathname);
                  window.scrollTo(0, 0);
                }
              }
            }}
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(
                (content || "").replace(/<style>[^\0]*?<\/style>/g, ""),
                {
                  allowedTags: sanitizeHtml.defaults.allowedTags.concat([
                    "details",
                    "summary",
                    "b",
                    "img",
                    "a",
                    "iframe",
                    "canvas"
                  ]),
                  allowedAttributes: {
                    "*": [
                      "id",
                      "href",
                      "target",
                      "class",
                      "style",
                      "align",
                      "width",
                      "height",
                      "name",
                      "title",
                      "scrolling",
                      "frameborder",
                      "data-view",
                      "src"
                    ]
                  },
                  allowedIframeDomains: ["adialog.no", "nordhordlandskart.no"]
                }
              )
            }}
          />
        )}
      </div>
    );
  }
}

WiderPageView.propTypes = {
  t: PropTypes.func.isRequired,
  page: PropTypes.object,
  pageLoading: PropTypes.bool,
  loadPage: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(translate("WiderPageView")(WiderPageView)));
