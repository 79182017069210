import React from "react";
import { translate } from "react-translate";

const Page404 = ({ t }) => {
  return (
    <div>
      <h3>{t("pageNotFound")}</h3>
      <p>
        {t("checkAddress")} <a href="/">{t("homePage")}</a>
      </p>
    </div>
  );
};

export default translate("Page404")(Page404);
