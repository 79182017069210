import React from "react";
import Loading from "../../component/Loading/Loading";

const HoursItem = ({ t, item }) =>
  !item ? (
    <Loading />
  ) : (
    <>
      <h4>{t("title")}</h4>
      <p>
        <span>{t("monday")}</span>
        <span>{item.mandag}</span>
      </p>
      <p>
        <span>{t("tuesday")}</span>
        <span>{item.tirsdag}</span>
      </p>
      <p>
        <span>{t("wednesday")}</span>
        <span>{item.onsdag}</span>
      </p>
      <p>
        <span>{t("thursday")}</span>
        <span>{item.torsdag}</span>
      </p>
      <p>
        <span>{t("friday")}</span>
        <span>{item.fredag}</span>
      </p>
      <p>
        <span>{t("saturday")}</span>
        <span>{item.lordag}</span>
      </p>
      <p>
        <span>{t("sunday")}</span>
        <span>{item.sondag}</span>
      </p>
      {item.unntak && item.unntak.length && (
        <div className="holidays">
          <h4>{t("unntak")}</h4>
          {item.unntak.split(/\n/g).map((u, i) =>
            u.length < 1 ? (
              ""
            ) : (
              <p key={i}>
                {u}
                <br />
              </p>
            )
          )}
        </div>
      )}
    </>
  );

export default HoursItem;
