import React from "react";
import { Select } from "@blueprintjs/select";
import { Button, MenuItem, ControlGroup } from "@blueprintjs/core";
import "./Bookmarks.scss";
import { withRouter } from "react-router";
import translate from "react-translate/lib/translate";

const bookmarks = {};

export function addBookmark(category, id, title, value) {
  loadBookmarks(category);
  bookmarks[category].push({ id, title, value });
  saveBookmarks(category);
}

export function removeBookmark(category, id) {
  bookmarks[category] = loadBookmarks(category).filter(
    bookmark => bookmark.id !== id
  );
  saveBookmarks(category);
}

export function saveBookmarks(category) {
  localStorage.setItem(
    "bookmarks-" + category,
    JSON.stringify(bookmarks[category])
  );
}

export function isBookmarked(category, id) {
  if (!bookmarks[category]) loadBookmarks(category);
  return bookmarks[category].findIndex(bookmark => bookmark.id === id) !== -1;
}

export function loadBookmarks(category) {
  bookmarks[category] =
    JSON.parse(localStorage.getItem("bookmarks-" + category) || "[]") || [];
  return bookmarks[category];
}

export function createBookmarks(category) {
  return {
    addBookmark: (id, title, value) => addBookmark(category, id, title, value),
    removeBookmark: id => removeBookmark(category, id),
    isBookmarked: id => isBookmarked(category, id)
  };
}

export class Bookmarks extends React.Component {
  render() {
    const { history, category, url, t } = this.props;
    const bookmarks = loadBookmarks(category);
    return (
      <ControlGroup fill className="bookmarks-wrapper">
        <Select
          fill
          items={bookmarks || []}
          itemRenderer={(bookmark, { handleClick }) => (
            <MenuItem
              key={bookmark.id}
              text={bookmark.title}
              onClick={handleClick}
            />
          )}
          noResults={<MenuItem disabled text={t("noBookmarks")} />}
          filterable={false}
          onItemSelect={({ value }) => {
            history.push(url, {
              address: value
            });
          }}
          popoverProps={{ minimal: true }}
        >
          <Button text={t("bookmarks")} fill rightIcon="caret-down" />
        </Select>
      </ControlGroup>
    );
  }
}

export default withRouter(translate("Bookmarks")(Bookmarks));
