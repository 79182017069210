import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import { connect } from "react-redux";
import { VectorSource, provideMapState } from "@avinet/react-openlayers";
import GeometryCollection from "ol/geom/GeometryCollection";
import Collapsible from "../../component/Collapsible";
import Loading from "../../component/Loading/Loading";
import { getDistance } from "ol/sphere";
import WKT from "ol/format/WKT";
import { buffer } from "ol/extent";
import { Style, Icon, Stroke } from "ol/style";
import container from "../../static/hyttekonteinar.png";
import config from "../../config";
import { getDistanceFromPoint } from "../../data/routes";

const NUMBER_OF_ROUTES_TO_CALCULATE = 3;
const NUMBLER_OF_ROUTES_TO_DISPLAY = 2;

const mapStateToProps = state => ({
  records: state.hyttekonteinarar.records,
  loading: state.hyttekonteinarar.loading
});

class Hyttekonteinarar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closest: undefined,
      loadingRoutes: false,
      displayRouteFeatures: [],
      activeContainerFeatureId: 0
    };
  }

  static propTypes = {
    address: PropTypes.object.isRequired,
    records: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    fitViewAndZoom: PropTypes.func.isRequired
  };

  async componentDidMount() {
    if (
      this.props.records.length > 0 &&
      !this.state.containers &&
      !this.state.loadingRoutes
    ) {
      this.setState({ loadingRoutes: true });
      const ap = this.props.address.olFeature
        .getGeometry()
        .clone()
        .transform(config.mapProjection, "EPSG:4326");
      const originGeometry = this.props.address.olFeature
        .getGeometry()
        .clone()
        .transform("EPSG:3857", "EPSG:32633");
      console.log({
        originGeometry,
        config,
        originalGeom: this.props.address.olFeature.getGeometry()
      });
      var origin = {
        east: originGeometry.getCoordinates()[0],
        north: originGeometry.getCoordinates()[1]
      };
      const format = new WKT();
      const pointsWithDistances = this.props.records
        .map(r => {
          const p = r.olFeature
            .getGeometry()
            .clone()
            .transform(config.mapProjection, "EPSG:4326");
          r.distance = getDistance(p.getCoordinates(), ap.getCoordinates());
          return r;
        })
        .sort((a, b) => {
          return a.distance - b.distance;
        });

      //   console.log({ pointsWithDistances });
      const points = pointsWithDistances
        .slice(0, NUMBER_OF_ROUTES_TO_CALCULATE)
        .map(r => {
          try {
            const coords = format
              .readFeature(r.geom_wkt, {
                dataProjection: "EPSG:3857",
                featureProjection: "EPSG:32633"
              })
              .getGeometry()
              .getCoordinates();
            return {
              id: r.id,
              point: {
                east: coords[0],
                north: coords[1]
              }
            };
          } catch (ex) {
            console.log("Could not transform coords for ", r, ex);
            return null;
          }
        });
      const distanceResult = await getDistanceFromPoint(origin, points);
      if (
        distanceResult &&
        distanceResult.success &&
        distanceResult.result.length > 0
      ) {
        for (var i = 0; i < NUMBER_OF_ROUTES_TO_CALCULATE; i++) {
          const pwd = pointsWithDistances[i];
          const calculatedCostAndRoute = distanceResult.result.find(
            d => d.id === pwd.id
          );
          pwd.cost = calculatedCostAndRoute.cost;
          pwd.calculatedRoute = calculatedCostAndRoute.route;
          pointsWithDistances[i] = pwd;
        }
      }
      this.setState({ containers: pointsWithDistances, loadingRoutes: false });
    }
  }

  onExpand = () => {
    const { fitViewAndZoom, address } = this.props;
    const { containers } = this.state;
    //   if (containers.length > 0) {
    //     containers.slice(0, NUMBLER_OF_ROUTES_TO_DISPLAY).forEach(c => {
    //       geoms.push(c.olFeature.getGeometry());
    //     });
    //     this.setDisplayRoute(containers[0]);
    //   }
    let geoms = [address.olFeature.getGeometry()];
    const closest =
      containers &&
      containers.slice(0, NUMBLER_OF_ROUTES_TO_DISPLAY).sort((a, b) => {
        return a.cost - b.cost;
      });
    if (closest.length > 0) {
      closest.forEach(c => {
        geoms.push(c.olFeature.getGeometry());
      });
      this.setDisplayRoute(closest[0]);
    }
    const extent = buffer(new GeometryCollection(geoms).getExtent(), 1.2);
    fitViewAndZoom(extent, config.fitViewAndZoom.in);
  };

  onCollapse = () => {
    const { fitViewAndZoom } = this.props;
    fitViewAndZoom(
      this.props.address.olFeature.getGeometry(),
      config.fitViewAndZoom.out
    );
    this.setState({ displayRouteFeatures: [], activeContainerFeatureId: 0 });
  };

  setDisplayRoute(container) {
    const format = new WKT();
    console.log("active container: ", container);
    const routeFeatures = [];
    if (container.calculatedRoute && container.calculatedRoute.length > 0) {
      container.calculatedRoute.forEach(wkt => {
        const f = format.readFeature(wkt, {
          dataProjection: "EPSG:32633",
          featureProjection: "EPSG:3857"
        });
        routeFeatures.push(f);
      });
    }

    this.setState({
      displayRouteFeatures: routeFeatures,
      activeContainerFeatureId: container.id
    });
  }

  render() {
    const { t, loading } = this.props;
    const { containers, loadingRoutes } = this.state;
    const closest =
      containers &&
      containers.slice(0, NUMBLER_OF_ROUTES_TO_DISPLAY).sort((a, b) => {
        return a.cost - b.cost;
      });
    return (
      <Collapsible
        className={
          loading || loadingRoutes ? "hushald hushald-loading" : "hushald"
        }
        header={
          loading || loadingRoutes ? (
            <React.Fragment>
              <span>{t("loadingClosest")}</span>
              {(loading || loadingRoutes) && <Loading />}
            </React.Fragment>
          ) : (
            t("closest")
          )
        }
        onExpand={this.onExpand}
        onCollapse={this.onCollapse}
        toggle={
          <VectorSource
            features={closest ? closest.map(({ olFeature }) => olFeature) : []}
            layerName="hyttekonteinere-layer"
            key="hyttekonteinere-layer"
            selectable={false}
            styleFn={() =>
              new Style({
                image: new Icon({
                  src: container,
                  scale: 1.2
                })
              })
            }
          />
        }
      >
        {closest ? (
          closest.map(c => (
            <div
              style={{
                border:
                  this.state.activeContainerFeatureId === c.id
                    ? "1px solid #1b645b"
                    : "none",
                padding:
                  this.state.activeContainerFeatureId === c.id ? "5px" : "0"
              }}
              key={c.id}
              className="hyttekonteinere--content"
            >
              <h3
                onClick={() => this.setDisplayRoute(c)}
                style={{ cursor: "pointer" }}
              >
                {c.name}
              </h3>
              {c.cost && (
                <div>
                  {t("kjoretid")}: {c.cost.toFixed(1)} min
                </div>
              )}
              <div>
                {t("rute")}:{c.route}
              </div>
            </div>
          ))
        ) : (
          <div className="hyttekonteinere--no-results">{t("noResults")}</div>
        )}
        <VectorSource
          features={this.state.displayRouteFeatures}
          layerName="hyttekonteinere--routes-layer"
          key="hyttekonteinere-routes-layer"
          selectable={false}
          styleFn={() =>
            new Style({
              stroke: new Stroke({
                color: "#1b645b",
                width: 3
              })
            })
          }
        />
      </Collapsible>
    );
  }
}

export default connect(
  mapStateToProps,
  undefined
)(translate("Hyttekonteinarar")(provideMapState(Hyttekonteinarar)));
