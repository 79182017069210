import React from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";
import { translate } from "react-translate";
import "./Collapsible.scss";

class Collapsible extends React.Component {
  static propTypes = {
    expanded: PropTypes.bool,
    children: PropTypes.node,
    header: PropTypes.node,
    className: PropTypes.string,
    onExpand: PropTypes.func,
    onCollapse: PropTypes.func,
    toggle: PropTypes.node
  };

  constructor(props) {
    super(props);

    this.state = {
      expanded: props.collapsed
    };
  }

  onClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.onExpand && !prevState.expanded && this.state.expanded) {
      this.props.onExpand();
    }

    if (this.props.onCollapse && prevState.expanded && !this.state.expanded) {
      this.props.onCollapse();
    }
  }

  render() {
    const { header, children, className, toggle, t } = this.props;
    const { expanded } = this.state;
    return (
      <div className={["collapsible--root", className].join(" ")}>
        <div className="collapsible--header" onClick={this.onClick}>
          {header}
          <span className="header--nonMobile"> {t("clickToView")}</span>
          <Icon name={expanded ? "chevronup" : "chevrondown"} />
        </div>
        <div
          className={["collapsible--content", expanded ? "open" : ""].join(" ")}
        >
          {children}
          {expanded && toggle}
        </div>
      </div>
    );
  }
}

export default translate("Collapsible")(Collapsible);
