import { useMemo, useCallback } from "react";
import useDigiThemeData from "./useDigiThemeData";

export default function useNewsCategories() {
  const nyhetskategoriDefinition = useMemo(() => {
    return {
      type: "nyhetskategori"
    };
  }, []);
  const [kategorier, loadingKategorier] = useDigiThemeData(
    nyhetskategoriDefinition
  );

  const mappedCategories = useMemo(() => {
    if (kategorier && kategorier.length > 0) {
      const m = {};
      kategorier.forEach(c => {
        m[c.id] = c.tag;
      });
      return m;
    }
    return {};
  }, [kategorier]);

  const getCategoryName = useCallback(
    categoryId => {
      try {
        return mappedCategories[categoryId];
      } catch (ex) {
        return null;
      }
    },
    [mappedCategories]
  );

  return [mappedCategories, getCategoryName, loadingKategorier];
}
