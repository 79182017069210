export default {
  locale: "en",

  App: {
    locale: "en",
    zoomInTip: "Zoom in",
    zoomOutTip: "Zoom out",
    trackTip: "My location",
    basemapGroup: "Basemap layers"
  },

  NavBar: {
    locale: "no",
    menu: "Menu",
    header: "Renovasjon i Nordhordland, Gulen og Solund"
  },

  IndexView: {
    local: "no"
  }
};
