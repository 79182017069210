import React from "react";
import PropTypes from "prop-types";
import {
  scopedBindActionCreators,
  digiThemeActions
} from "react-adaptive-utils";
import { connect } from "react-redux";
import config from "../config";

const mapStateToProps = state => ({
  glasskonteinere: {
    records: state.glasskonteinere.records,
    loading: state.glasskonteinere.loading
  },
  gjennvinningsstasjoner: {
    records: state.gjenvinningsstasjoner.records,
    loading: state.gjenvinningsstasjoner.loading
  },
  hyttekontainarar: {
    records: state.hyttekonteinarar.records,
    loading: state.hyttekonteinarar.loading
  },
  eternit: {
    records: state.eternit.records,
    loading: state.eternit.loading
  }
});

const mapDispatchToProps = dispatch => ({
  glasskonteinere: scopedBindActionCreators(
    Object.assign({}, digiThemeActions),
    dispatch,
    "glasskonteinere"
  ),
  gjenvinningsstasjoner: scopedBindActionCreators(
    Object.assign({}, digiThemeActions),
    dispatch,
    "gjenvinningsstasjoner"
  ),
  hyttekonteinarar: scopedBindActionCreators(
    Object.assign({}, digiThemeActions),
    dispatch,
    "hyttekonteinarar"
  ),
  eternit: scopedBindActionCreators(
    Object.assign({}, digiThemeActions),
    dispatch,
    "eternit"
  )
});

class DefaultLoader extends React.Component {
  static propTypes = {
    glasskonteinere: PropTypes.object.isRequired,
    gjennvinningsstasjoner: PropTypes.object.isRequired,
    hyttekontainarar: PropTypes.object.isRequired,
    eternit: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.readGlasskonteinere();
    this.readGjenvinningsstasjoner();
    this.readHyttekonteinarar();
    this.readEternit();
  }

  readGlasskonteinere() {
    this.props.glasskonteinere.readFiltered(
      config.themes.glasskonteinere.columns,
      {
        filterColumns: [
          {
            name: "geom",
            comparisonOperator: "IS NOT NULL"
          }
        ]
      },
      config.mapProjCode,
      0,
      0
    );
  }

  readGjenvinningsstasjoner() {
    this.props.gjenvinningsstasjoner.readFiltered(
      config.themes.gjenvinningsstasjoner.columns,
      {
        filterColumns: [
          {
            name: "geom",
            comparisonOperator: "IS NOT NULL"
          }
        ]
      },
      config.mapProjCode,
      0,
      0
    );
  }

  readHyttekonteinarar() {
    this.props.hyttekonteinarar.readFiltered(
      config.themes.hyttekonteinarar.columns,
      {
        filterColumns: [
          {
            name: "geom",
            comparisonOperator: "IS NOT NULL"
          }
        ]
      },
      config.mapProjCode,
      0,
      0
    );
  }

  readEternit() {
    this.props.eternit.readFiltered(
      config.themes.eternit.columns,
      {
        filterColumns: [
          {
            name: "geom",
            comparisonOperator: "IS NOT NULL"
          }
        ]
      },
      config.mapProjCode,
      0,
      0
    );
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLoader);
