import React from "react";
import { olUtils, provideMapState } from "@avinet/react-openlayers";
import PropTypes from "prop-types";

const ngirFeature = olUtils.createFeatureFromGeometry(
  olUtils.createPoint([585200, 8546016]),
  { name: "Om Oss" }
);

export class OmOss extends React.Component {
  static propTypes = {
    fitViewAndZoom: PropTypes.func.isRequired,
    children: PropTypes.node
  };

  componentDidMount() {
    this.props.fitViewAndZoom(ngirFeature.getGeometry(), 17);
  }

  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

export default provideMapState(OmOss);
