import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  provideMapState,
  WmsLayer,
  VectorSource
} from "@avinet/react-openlayers";
import { Style, Icon } from "ol/style";
import recycle from "../../static/recyclewback.svg";
import config from "../../config";

const mapStateToProps = state => ({
  records: state.gjenvinningsstasjoner.records,
  loading: state.gjenvinningsstasjoner.loading
});

class Tenester extends React.Component {
  constructor() {
    super();
    this.state = {
      isKonteinerleige: false
    };
  }

  componentDidMount() {
    if (this.props.records && this.props.records.length) this.setMapCenter();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location.hash !== this.props.location.hash ||
      prevProps.records.length !== this.props.records.length
    ) {
      this.setMapCenter();
    }
  }

  setMapCenter = () => {
    const {
      location: { hash },
      fitViewAndZoom,
      records
    } = this.props;

    const name = decodeURIComponent(hash.replace("#", ""));
    let record = records.find(r => r.navn.toLowerCase() === "kjevikdalen");
    if (record) this.setState({ olFeature: record.olFeature });
    if (name === "konteinarleige") {
      fitViewAndZoom(config.initExtent);
      this.setState({ isKonteinerleige: true });
      return;
    }

    fitViewAndZoom(record.olFeature.getGeometry(), config.maxZoom);
    this.setState({ isKonteinerleige: false });
  };

  render() {
    const { isKonteinerleige, olFeature } = this.state;
    return (
      <React.Fragment>
        {isKonteinerleige ? (
          <WmsLayer
            uri="https://kart.nordhordlandskart.no/wms.ashx?"
            id="tenseter"
            layerName="layer_316"
            name="tenester"
            key="tenester"
          />
        ) : (
          <VectorSource
            features={olFeature ? [olFeature] : []}
            layerName="avvik-layer"
            key="avvik-layer"
            selectable={false}
            zIndex={0}
            style={
              new Style({
                image: new Icon({
                  src: recycle,
                  scale: 1.4
                })
              })
            }
          ></VectorSource>
        )}
      </React.Fragment>
    );
  }
}

Tenester.propTypes = {
  fitViewAndZoom: PropTypes.func.isRequired,
  children: PropTypes.node,
  location: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  undefined
)(withRouter(provideMapState(Tenester)));
