import React, { useState, useCallback, useRef } from "react";

import { connect } from "react-redux";
import { translate } from "react-translate";
import { Link, withRouter } from "react-router-dom";

import "./Hours.scss";
import useClickedOutside from "../../hooks/useClickedOutside";

const mapStateToProps = state => ({
  records: state.gjenvinningsstasjoner.records
});

const Hours = ({ t, naering, records, className }) => {
  const [active, setActive] = useState(false);
  const ref = useRef();

  const handleClick = useCallback(() => {
    setActive(!active);
  }, [active]);

  useClickedOutside(ref, handleClick);

  const getWeekOfMonth = () => {
    var firstWeekday = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    ).getDay();
    var offsetDate = new Date().getDate() + firstWeekday - 1;
    return Math.floor(offsetDate / 7);
  };

  const apningstider = () => {
    let hours = undefined;
    if (naering) {
      hours = records.filter(r => r.is_naering);
    } else {
      hours = records.filter(r => r.is_privat);
    }

    const solundIdx = hours.findIndex(h => h.navn === "Solund");
    if (solundIdx !== -1) {
      if (getWeekOfMonth() === 2) {
        hours[solundIdx].lordag = "09.00 - 15.00";
      }
    }

    return hours.sort((a, b) => a.sort - b.sort);
  };

  return (
    <div className={`hours ${className ? className : ""}`}>
      <button
        className={["btn-link", active ? "active" : ""].join(" ")}
        type="button"
        onClick={handleClick}
        tabIndex={0}
      >
        {t("showOpeningHours")}
      </button>

      {active && (
        <div className="hours-all" ref={ref}>
          <p>{t("desc")}</p>
          <div className="hours-all__container">
            {apningstider().map(r => (
              <Link
                to={`/gjenvinningsstasjonar${r.uri}`}
                key={r.id}
                onClick={handleClick}
                className={[
                  "hours-all__container--item",
                  r.navn === "Kundeservice" || !r.gjenvinningsstasjon
                    ? r.navn.includes("Vrak")
                      ? "naering"
                      : "kundeservice"
                    : "gjenvinningsstasjon"
                ].join(" ")}
              >
                <h3>{r.navn.toUpperCase()}</h3>
                <p>{r.description}</p>
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(
  mapStateToProps,
  undefined
)(translate("Hours")(withRouter(Hours)));
