import React from "react";
import FritakSkjema from "../container/skjema/FritakSkjema";
import BestillingSkjema from "../container/skjema/BestillingSkjema";
import SlamtommingSkjema from "../container/skjema/SlamtommingSkjema";
import MinirenseanleggSkjema from "../container/skjema/MinirenseanleggSkjema";
import { Switch, Route } from "react-router-dom";
import Page404 from "../component/Page404";
import "./SkjemaView.scss";

export default function SkjemaView() {
  return (
    <div className="skjemaView--wrapper">
      <Switch>
        <Route path="/skjema/endring" component={BestillingSkjema} />
        <Route path="/skjema/gebyr" component={FritakSkjema} />
        <Route path="/skjema/slamtomming" component={SlamtommingSkjema} />
        <Route
          path="/skjema/minireinseanlegg"
          component={MinirenseanleggSkjema}
        />
        <Route component={Page404} />
      </Switch>
    </div>
  );
}
