import React from "react";
import { withRouter } from "react-router-dom";
import {
  olUtils,
  provideMapState,
  VectorSource
} from "@avinet/react-openlayers";
import { Icon, Style, Text, Fill } from "ol/style";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import config from "../../config";
import recycle from "../../static/recyclewback.svg";

const mapStateToProps = state => ({
  records: state.gjenvinningsstasjoner.records,
  loading: state.gjenvinningsstasjoner.loading
});

export class OmOss extends React.Component {
  static propTypes = {
    fitViewAndZoom: PropTypes.func.isRequired,
    children: PropTypes.node,
    records: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    location: PropTypes.object.isRequired
  };

  componentDidMount() {
    if (this.props.records && this.props.records.length) this.setMapCenter();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.params !== this.props.match.params.params ||
      prevProps.records.length !== this.props.records.length
    ) {
      this.setMapCenter();
    }
  }

  setMapCenter = () => {
    const {
      match: {
        params: { params }
      },
      fitViewAndZoom,
      records
    } = this.props;

    var record = records.find(
      r => r.uri.toLowerCase() === `/${params.toLowerCase()}`
    );
    if (record) {
      fitViewAndZoom(record.olFeature.getGeometry(), config.maxZoom);
      return;
    }
    const extent = olUtils.getExtentFromFeatures(this.getFeatures);
    fitViewAndZoom(extent);
  };

  get getFeatures() {
    const { records } = this.props;

    return records.filter(r => r.gjenvinningsstasjon).map(r => r.olFeature);
  }

  featureStyle = f => {
    return new Style({
      image: new Icon({
        src: recycle,
        scale: 1.4
      }),
      text: new Text({
        text: f.get("navn"),
        padding: [2, 2, 2, 2],
        offsetX: 40,
        offsetY: 15,
        backgroundFill: new Fill({ color: "rgba(255,255,255,.8)" }),
        scale: 1.4
      })
    });
  };

  render() {
    return (
      <React.Fragment>
        <VectorSource
          features={this.getFeatures}
          layerName="gjenvinningsstasjonar-layer"
          key="gjenvinningsstasjonar-layer"
          selectable={false}
          styleFn={() => this.featureStyle}
        />
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  undefined
)(provideMapState(withRouter(OmOss)));
