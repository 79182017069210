import React from "react";
import PropTypes from "prop-types";
import GeometryCollection from "ol/geom/GeometryCollection";
import Collapsible from "../../component/Collapsible";
import Loading from "../../component/Loading/Loading";
import Config from "../../config/index";
import { translate } from "react-translate";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { VectorSource, provideMapState } from "@avinet/react-openlayers";
import { buffer } from "ol/extent";
import { Style, Icon, Fill, Text } from "ol/style";
import recycle from "../../static/recyclewback.svg";
import "./Gjenvinningsstasjon.scss";

const mapStateToProps = state => ({
  records: state.gjenvinningsstasjoner.records,
  loading: state.gjenvinningsstasjoner.loading
});

class Gjenvinningsstasjon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closest: undefined
    };
  }

  static propTypes = {
    address: PropTypes.object.isRequired,
    records: PropTypes.array.isRequired,
    loading: PropTypes.bool
  };

  get getFeatures() {
    const { records } = this.props;

    return records.filter(r => r.gjenvinningsstasjon).map(r => r.olFeature);
  }

  onExpand = () => {
    const { fitViewAndZoom, address, records } = this.props;
    const geoms = records
      .filter(r => r.gjenvinningsstasjon)
      .map(r => r.olFeature.getGeometry());
    geoms.push(address.olFeature.getGeometry());

    var extent = buffer(new GeometryCollection(geoms).getExtent(), 1.2);
    fitViewAndZoom(extent, Config.fitViewAndZoom.in);
  };

  onCollapse = () => {
    const { fitViewAndZoom, address } = this.props;
    fitViewAndZoom(address.olFeature.getGeometry(), Config.fitViewAndZoom.out);
  };

  get weekday() {
    const dayOfWeek = new Date().getDay();
    switch (dayOfWeek) {
      case 1:
        return "mandag";
      case 2:
        return "tirsdag";
      case 3:
        return "onsdag";
      case 4:
        return "torsdag";
      case 5:
        return "fredag";
      case 6:
        return "lordag";
      default:
        return "";
    }
  }

  renderGjenvinningsstasjoner() {
    const { records } = this.props;
    // console.log({ records });
    return (
      <div className="gjenvinningsstasjon--flex-wrapper">
        {records
          .filter(r => r.gjenvinningsstasjon)
          .map(r => (
            <div key={r.id} className="gjenvinningsstasjon--flex-item">
              <Link to={`/gjenvinningsstasjonar${r.uri}`}>
                <img src={recycle} alt={r.navn} />
                {r.navn}
              </Link>

              {/* <div key={r.id} className="gjenvinningsstasjon--hours">
                {/* <Link
                  className="btn"
                  to={`/gjenvinningsstasjonar#${r.navn.toLowerCase()}`}
                >
                  {r.navn}
                </Link> */}
              {/* <h4>{r.navn}</h4>
                 <p>
                  <span>{t(this.weekday)} </span>
                  <span>{r[this.weekday]}</span>
                </p>
                {r.unntak && r.unntak.length && (
                  <div className="holidays">
                    <div className="holidays--header">{t("unntak")}</div>
                    {r.unntak.split(/\n/g).map((u, i) =>
                      u.length < 1 ? (
                        ""
                      ) : (
                        <p key={i}>
                          {u}
                          <br />
                        </p>
                      )
                    )}
                  </div>
                )} 
                      </div> */}
            </div>
          ))}
      </div>
    );
  }

  featureStyle = f => {
    return new Style({
      image: new Icon({
        src: recycle,
        scale: 1.4
      }),
      text: new Text({
        text: f.get("navn"),
        padding: [2, 2, 2, 2],
        offsetX: 40,
        offsetY: 15,
        backgroundFill: new Fill({ color: "rgba(255,255,255,.8)" }),
        scale: 1.4
      })
    });
  };

  render() {
    const { t, loading, records } = this.props;
    const features = this.getFeatures;

    return (
      <Collapsible
        className="hushald"
        header={
          loading ? (
            <React.Fragment>
              {t("header")}
              {loading && <Loading />}{" "}
            </React.Fragment>
          ) : (
            t("header")
          )
        }
        onCollapse={this.onCollapse}
        onExpand={this.onExpand}
        toggle={
          <VectorSource
            features={features}
            layerName="gjenvinningsstasjon-layer"
            key="gjenvinningsstasjon-layer"
            selectable={false}
            styleFn={() => this.featureStyle}
          />
        }
      >
        <div className="gjenvinningsstasjon--content">
          {/* <h3>{t("openingHoursToday")}</h3> */}
          {records.length > 0 ? (
            this.renderGjenvinningsstasjoner()
          ) : (
            <div className="gjenvinningsstasjon--no-results">
              {t("noResults")}
            </div>
          )}
          {/* <Link className="btn" to="/gjenvinningsstasjonar">
            {t("meirInfo")}
          </Link> */}
        </div>
      </Collapsible>
    );
  }
}

export default connect(
  mapStateToProps,
  undefined
)(translate("Gjenvinningsstasjon")(provideMapState(Gjenvinningsstasjon)));
