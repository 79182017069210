import React from "react";
import PropTypes from "prop-types";
import WKT from "ol/format/WKT";
import Config from "../../config";
import Loading from "../../component/Loading/Loading";
import Collapsible from "../../component/Collapsible";
import { translate } from "react-translate";
import {
  scopedBindActionCreators,
  digiThemeActions
} from "react-adaptive-utils";
import { connect } from "react-redux";
import { VectorSource, provideMapState } from "@avinet/react-openlayers";
import { Style, Fill, Stroke } from "ol/style";
import matDunk from "../../static/mat.png";
import restDunk from "../../static/rest.png";
import pappDunk from "../../static/papp.png";
import plastePose from "../../static/plast.jpg";

import "./Hentedag.scss";

const mapStateToProps = state => ({
  records: state.hentedag.records,
  loading: state.hentedag.loading
});

const mapDispatchToProps = dispatch =>
  scopedBindActionCreators(
    Object.assign({}, digiThemeActions),
    dispatch,
    "hentedag"
  );

class Hentedag extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  static propTypes = {
    address: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.readHentedag();
  }

  readHentedag() {
    const { address } = this.props;
    const filter = Config.themes.hentedag.intersectFilter;
    filter.value =
      "SRID=" +
      Config.mapProjCode +
      ";" +
      new WKT().writeFeature(address.olFeature);

    this.props.readFiltered(
      Config.themes.hentedag.columns,
      {
        filterColumns: [filter]
      },
      Config.mapProjCode,
      0,
      1
    );
  }

  static getDerivedStateFromProps(props, state) {
    if (props.records.length > 0 && !state.olFeature) {
      state.olFeature = new WKT().readFeature(props.records[0].geom_wkt);
    }
    if (props.loading) {
      state.olFeature = undefined;
    }
    return state;
  }

  onExpand = () => {
    const { fitViewAndZoom } = this.props;
    const { olFeature } = this.state;
    if (olFeature)
      fitViewAndZoom(olFeature.getGeometry(), Config.fitViewAndZoom.in);
  };

  onCollapse = () => {
    const { fitViewAndZoom } = this.props;
    fitViewAndZoom(
      this.props.address.olFeature.getGeometry(),
      Config.fitViewAndZoom.out
    );
  };

  render() {
    const { t, records, loading } = this.props;
    const { olFeature } = this.state;

    return (
      <div className="hentedag--root">
        <Collapsible
          className="hushald"
          onCollapse={this.onCollapse}
          onExpand={this.onExpand}
          header={
            records.length < 1 ? (
              <React.Fragment>
                {t("tommekalender")}
                {loading && <Loading />}{" "}
              </React.Fragment>
            ) : (
              t("tommekalenderwnumber").replace(
                "{route}",
                records[0].sone_ngir_2017
              )
            )
          }
          toggle={
            <VectorSource
              features={olFeature ? [olFeature] : []}
              layerName="hentedag-layer"
              key="hentedag-layer"
              selectable={false}
              zIndex={0}
              styleFn={() =>
                new Style({
                  fill: new Fill({ color: "rgba(71, 139, 180, .1)" }),
                  stroke: new Stroke({ color: "rgb(71,139,180)" })
                })
              }
            />
          }
        >
          {!loading && records.length > 0 ? (
            <div className="hentedag--content">
              <section>
                <h3>{t("thisWeek", { veke: records[0].aktuelluke })}</h3>
                <div>
                  <span>{t("hentedag")}: </span>
                  <span>{records[0].hentedag}</span>
                </div>
                <div>
                  <span>{t("type")}: </span>
                  <span>{records[0].fraksjon_dynamisk}</span>
                </div>
                {records[0].fraksjon_dynamisk.indexOf("Restavfall") > -1 ? (
                  <div className="hentedag--dunk-container">
                    <img
                      src={restDunk}
                      alt={t("restavfall")}
                      title={t("restavfall")}
                    />
                    <img
                      src={matDunk}
                      alt={t("bioavfall")}
                      title={t("bioavfall")}
                    />
                  </div>
                ) : (
                  <div className="hentedag--dunk-container">
                    <img src={pappDunk} alt={t("papp")} title={t("papp")} />
                    <img
                      src={matDunk}
                      alt={t("bioavfall")}
                      title={t("bioavfall")}
                    />
                    <img src={plastePose} alt={t("plast")} title={t("plast")} />
                  </div>
                )}
              </section>
              <section>
                <h3>{t("nextWeek", { veke: records[0].nesteuke })}</h3>
                <div>
                  <span>{t("hentedag")}: </span>
                  <span>{records[0].hentedag_nesteveke}</span>
                </div>
                <div>
                  <span>{t("type")}: </span>
                  <span>{records[0].fraksjon_dynamisk_neste}</span>
                </div>
                {records[0].fraksjon_dynamisk_neste.indexOf("papp") > -1 ? (
                  <div className="hentedag--dunk-container">
                    <img src={pappDunk} alt={t("papp")} title={t("papp")} />
                    <img
                      src={matDunk}
                      alt={t("bioavfall")}
                      title={t("bioavfall")}
                    />
                    <img src={plastePose} alt={t("plast")} title={t("plast")} />
                  </div>
                ) : (
                  <div className="hentedag--dunk-container">
                    <img
                      src={restDunk}
                      alt={t("restavfall")}
                      title={t("restavfall")}
                    />
                    <img
                      src={matDunk}
                      alt={t("bioavfall")}
                      title={t("bioavfall")}
                    />
                  </div>
                )}
              </section>
            </div>
          ) : (
            !loading &&
            records.length === 0 && (
              <div className="hentedag--no-results">{t("noResults")}</div>
            )
          )}
        </Collapsible>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(provideMapState(translate("Hentedag")(Hentedag)));
