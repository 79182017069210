import React from "react";
import { translate } from "react-translate";
import { VectorSource, provideMapState } from "@avinet/react-openlayers";
import GeometryCollection from "ol/geom/GeometryCollection";
import Collapsible from "../../component/Collapsible";
import Loading from "../../component/Loading/Loading";
import WKT from "ol/format/WKT";
import { Style, Stroke, Fill, RegularShape } from "ol/style";
import config from "../../config";
import request from "superagent";

const SERVICED_RADIUS = 25;
const PICKUP_POINTS_WITHIN_DISTANCE = 300;

export async function getHentepunkter(point) {
  try {
    var res = await request
      .get(
        //   `https://localhost:44349/GarbagePickUpPoint?x=${point.x}&y=${point.y}&servicedRadius=${SERVICED_RADIUS}&pickupPointsWithinDistance=${PICKUP_POINTS_WITHIN_DISTANCE}`
        `${config.routeServiceUrl}/GarbagePickUpPoint?x=${point.x}&y=${point.y}&servicedRadius=${SERVICED_RADIUS}&pickupPointsWithinDistance=${PICKUP_POINTS_WITHIN_DISTANCE}`
      )
      .send()
      .set("Content-Type", "application/json");
    return res.body;
  } catch (ex) {
    console.error("DistancesToPoint error:", ex);
    return null;
  }
}

class Hentepunkt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closest: undefined,
      loadingRoutes: false,
      latestCoordinates: [],
      showDetails: false,
      currentHentepunkt: null
    };
    this.mapClickHandler = this.mapClickHandler.bind(this);
    this.mapDragHandler = this.mapDragHandler.bind(this);
  }

  mapClickHandler(evt) {
    // console.log("click", evt);
    this.setState({
      latestCoordinates: evt.pixel
    });
    if (this.state.showDetails) {
      this.setState({
        showDetails: false,
        currentHentepunkt: null,
        latestCoordinates: []
      });
    }
  }

  mapDragHandler(evt) {
    // console.log("drag", evt);
    this.setState({
      latestCoordinates: [],
      currentHentepunkt: null,
      showDetails: false
    });
  }

  async componentDidMount() {
    const format = new WKT();
    this.props.mapContext.map.on("click", this.mapClickHandler);
    this.props.mapContext.map.on("pointerdrag", this.mapDragHandler);
    this.setState({
      loadingRoutes: true
    });
    const ap = this.props.address.olFeature
      .getGeometry()
      .clone()
      .transform(config.mapProjection, "EPSG:4326");

    const coords = ap.getCoordinates();
    const containers = [];
    let points = await getHentepunkter({ x: coords[0], y: coords[1] });
    // console.log("getHentepunkter points: ", points);
    if (points && points.length > 0) {
      points = points
        .sort((a, b) => {
          return a.tommepunkt_dist - b.tommepunkt_dist;
        })
        .slice(0, 4);
      points.forEach(point => {
        const f = format.readFeature(point.tommepunkt, {
          dataProjection: "EPSG:4326",
          featureProjection: "EPSG:3857"
        });
        f.setProperties({
          tommepunkt_id: point.tommepunkt_id,
          tomt: point.last_position_time,
          position_dist: point.position_dist
        });
        f.onClick = () => {
          this.setState({ showDetails: true, currentHentepunkt: f });
        };
        point.olFeature = f;
        containers.push(point);
      });
    }
    console.log({ containers });
    this.setState({ containers, loadingRoutes: false });
  }

  onExpand = () => {
    const { fitViewAndZoom, address } = this.props;
    const { containers } = this.state;
    let geoms = [address.olFeature.getGeometry()];
    const closest = containers;
    if (closest.length > 0) {
      closest.forEach(c => {
        geoms.push(c.olFeature.getGeometry());
      });
    }
    // console.log({ geoms });
    const extent = new GeometryCollection(geoms).getExtent();
    fitViewAndZoom(extent, config.fitViewAndZoom.in);
  };

  onCollapse = () => {
    const { fitViewAndZoom } = this.props;
    fitViewAndZoom(
      this.props.address.olFeature.getGeometry(),
      config.fitViewAndZoom.out
    );
    this.setState({
      closest: undefined,
      loadingRoutes: false,
      latestCoordinates: [],
      showDetails: false,
      currentHentepunkt: null
    });
  };

  componentWillUnmount = () => {
    this.props.mapContext.map.un("click", this.mapClickHandler);
    this.props.mapContext.map.un("pointerdrag", this.mapDragHandler);
  };

  render() {
    const { t } = this.props;
    const { containers, loadingRoutes } = this.state;
    const closest = containers;

    const vectorFeatures = closest
      ? closest.map(({ olFeature }) => {
          olFeature.setStyle(
            new Style({
              image: new RegularShape({
                fill: new Fill({
                  color: olFeature.getProperties().tomt ? "#1b645b" : "#ac4900"
                }),
                stroke: new Stroke({ color: "white", width: 2 }),
                points: 3,
                radius: 12,
                rotation: Math.PI / 3,
                angle: 0
              })
            })
          );
          return olFeature;
        })
      : [];
    console.log({ vectorFeatures });
    return (
      <Collapsible
        className={loadingRoutes ? "hushald hushald-loading" : "hushald"}
        header={
          loadingRoutes ? (
            <React.Fragment>
              <span>{t("loadingClosest")}</span>
              {loadingRoutes && <Loading />}
            </React.Fragment>
          ) : (
            t("closest")
          )
        }
        onExpand={this.onExpand}
        onCollapse={this.onCollapse}
        toggle={
          <VectorSource
            features={vectorFeatures}
            layerName="hentepunkter-layer"
            key="hentepunkter-layer"
            selectable={true}
          />
        }
      >
        {closest ? (
          <div className="glasskonteinere--content">
            <div>{t("displayingPickupPoints")}</div>
          </div>
        ) : (
          <div className="glasskonteinere--no-results">{t("noResults")}</div>
        )}
        {this.state.latestCoordinates &&
          this.state.latestCoordinates.length > 0 &&
          this.state.showDetails && (
            <div
              onClick={() => {
                this.setState({
                  showDetails: false,
                  latestCoordinates: [],
                  currentHentepunkt: null
                });
              }}
              style={{
                backgroundColor: "#1b645b",
                color: "#fff",
                position: "fixed",
                padding: "10px",
                width: "270px",
                transform: `translate(-50%, 0)`,
                top:
                  this.state.latestCoordinates.length > 0
                    ? this.state.latestCoordinates[1] + 80
                    : 0,
                left:
                  this.state.latestCoordinates.length > 0
                    ? this.state.latestCoordinates[0]
                    : 0,
                display:
                  this.state.latestCoordinates.length > 0 &&
                  this.state.showDetails
                    ? "block"
                    : "none"
              }}
              className="hentepunkt-details"
            >
              <div>
                Hentepunkt{" "}
                {this.state.currentHentepunkt.getProperties().tommepunkt_id}
              </div>
              <div>
                {this.state.currentHentepunkt.getProperties().tomt
                  ? "Tømt: " +
                    this.state.currentHentepunkt
                      .getProperties()
                      .tomt.replace("T", " kl. ")
                  : "Ikke tømt siste uken"}
              </div>
            </div>
          )}
      </Collapsible>
    );
  }
}

export default translate("Hentepunkt")(provideMapState(Hentepunkt));
