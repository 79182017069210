import React from "react";
import WKT from "ol/format/WKT";
import PropTypes from "prop-types";
import Modal from "../component/Modal";
import Hours from "../container/Hours/Hours";
import Varsel from "../container/Varsel/Varsel";
import Hentedag from "../container/renovasjon/Hentedag";
import BilBatvrak from "../container/renovasjon/BilBatvrak";
import AvvikDropdown from "../container/Avvik/AvvikDropdown";
import Konteinerleie from "../container/konteiner/Konteinerleie";
import AndreTjenester from "../container/renovasjon/AndreTjenester";
import GlassKonteinere from "../container/konteiner/GlassKonteinere";
import Gjenvinningsstasjon from "../container/renovasjon/Gjenvinningsstasjon";
import MellombelsAvfallsmottak from "../container/konteiner/MellombelsAvfallsmottak";

import { connect } from "react-redux";
import { translate } from "react-translate";
import { Style, Text, Fill, Icon } from "ol/style";
import { withRouter, Link } from "react-router-dom";
import { createBookmarks } from "../component/Bookmarks";
import {
  scopedBindActionCreators,
  digiThemeActions
} from "react-adaptive-utils";
import {
  provideMapState,
  VectorSource,
  ensureMap
} from "@avinet/react-openlayers";

import config from "../config";

import myLocation from "../static/my_location.png";

import "./HushaldView.scss";
import Hentepunkt from "../container/konteiner/Hentepunkt";

const mapStateToProps = state => ({
  records: state.kommuneareal.records,
  loading: state.kommuneareal.loading
});

const mapDispatchToProps = dispatch =>
  scopedBindActionCreators(
    Object.assign({}, digiThemeActions),
    dispatch,
    "kommuneareal"
  );

const bookmarks = createBookmarks("hushald");

class HushaldView extends React.Component {
  constructor(props) {
    super(props);
    const wkt = new WKT();
    const {
      location: { state },
      history
    } = this.props;

    if (!state || !state.address) {
      history.push("/");
    } else {
      this.state = {
        invalidAddress: false,
        address: {
          olFeature: wkt.readFeature(state.address.geom, {
            dataProjection: "EPSG:4326",
            featureProjection: config.mapProjection
          }),
          ...state.address
        }
      };
    }
  }

  onBookmark = () => {
    const { address } = this.state;
    if (bookmarks.isBookmarked(address.id)) {
      bookmarks.removeBookmark(address.id);
    } else {
      bookmarks.addBookmark(address.id, this.addressTitle, {
        id: address.id,
        title: address.title,
        desc: address.desc,
        geom: address.geom
      });
    }
    this.setState({});
  };

  componentDidMount() {
    const { fitViewAndZoom } = this.props;
    const { address } = this.state;
    this.readKommuneareal();
    fitViewAndZoom(address.olFeature.getGeometry(), config.fitViewAndZoom.in);
  }

  readKommuneareal() {
    const { address } = this.state;
    const filter = config.themes.kommuneareal.intersectFilter;
    filter.value =
      "SRID=" +
      config.mapProjCode +
      ";" +
      new WKT().writeFeature(address.olFeature);

    this.props.readFiltered(
      config.themes.kommuneareal.columns,
      {
        filterColumns: [filter]
      },
      config.mapProjCode,
      0,
      1
    );
  }

  componentDidUpdate() {
    const { loading, records } = this.props;
    if (!loading && records.length === 0 && !this.state.invalidAddress) {
      this.setState({ invalidAddress: true });
    }
  }

  get addressTitle() {
    const { address } = this.state;
    return (
      address.title +
      ", " +
      address.desc.substring(0, address.desc.indexOf("("))
    );
  }

  render() {
    const { t } = this.props;
    const { address, invalidAddress } = this.state;

    return (
      <div className="hushald-view--root">
        <div className="hushald-view--header">
          <Link to="/hushald">{t("hushald")}</Link>
          <Hours />
        </div>
        <div className="hushald-view--content">
          <div className="hushald-view--selected-address">
            <span>{this.addressTitle}</span>
            <div>
              <Link to="/">({t("gobackAndSearch")})</Link>
            </div>
          </div>
          <div className="hushald-view--children">
            <Hentedag address={address} />
            <Hentepunkt address={address} />
            <GlassKonteinere address={address} />
            <Gjenvinningsstasjon address={address} />
            <Konteinerleie address={address} />
            <BilBatvrak address={address} />
            <AndreTjenester address={address} />
            <MellombelsAvfallsmottak address={address} />
            <AvvikDropdown address={address} />
            <Varsel address={address} />
          </div>
        </div>
        {address.olFeature && (
          <VectorSource
            features={[address.olFeature]}
            layerName="hushald-layer"
            key="hushald-layer"
            selectable={false}
            styleFn={() =>
              new Style({
                image: new Icon({
                  src: myLocation,
                  scale: 0.8
                }),
                text: new Text({
                  text: "Du er her",
                  padding: [2, 2, 2, 2],
                  offsetX: 50,
                  offsetY: 15,
                  backgroundFill: new Fill({ color: "rgba(255,255,255,.8)" }),
                  scale: 1.4
                })
              })
            }
            zIndex={100}
          />
        )}
        {invalidAddress && (
          <Modal>
            <p>Den valde adressa tilhører ikkje NGIR.</p>
            <Link className="btn" to="/">
              Tilbake til forsida
            </Link>
          </Modal>
        )}
      </div>
    );
  }
}

HushaldView.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  fitViewAndZoom: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  provideMapState(ensureMap(withRouter(translate("HushaldView")(HushaldView))))
);
