import React from "react";
import { Link } from "react-router-dom";

import "./LinkButton.scss";

const LinkButton = ({ location, imageText, imgSrc }) => {
  return (
    <Link className="link-button" to={location}>
      <img src={imgSrc} alt={imageText} />
    </Link>
  );
};

export default LinkButton;
