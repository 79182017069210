import React, { useMemo } from "react";
import News from "../container/News/News";
import Search from "../component/Search/Search";
import LinkButton from "../component/LinkButton";
import useDigiThemeData from "../hooks/useDigiThemeData";

import "./IndexView.scss";

const forsidelenkerDefinition = {
  type: "forsidelenker",
  filter: {
    filterColumns: [
      {
        name: "published",
        value: true
      }
    ],
    sortColumns: [
      {
        name: "priority"
      }
    ]
  },
  limit: 3
};

const IndexView = () => {
  const [forsidelenker, loadingForsidelenker] = useDigiThemeData(
    forsidelenkerDefinition
  );

  const renderForsidelenker = useMemo(() => {
    if (!forsidelenker || forsidelenker.length === 0) {
      return (
        //Loading dots
        <div className="frontpagelinks--loading">
          <span />
          <span />
          <span />
        </div>
      );
    }
    return forsidelenker.map(fl => {
      return (
        <LinkButton
          key={fl.id}
          location={fl.uri}
          imageText={fl.text}
          imgSrc={
            "https://nordhordlandskart.no/WebServices/generic/Media.asmx/Download?uuid=" +
            fl.image
          }
        />
      );
    });
  }, [forsidelenker]);

  return (
    <div className="index-view--root">
      <Search />
      <div className="button-container">{renderForsidelenker}</div>
      <News />
    </div>
  );
};

export default IndexView;
