import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import App from "./container/App";
import createStore from "./redux/createStore";
import { CmsConfig } from "react-adaptive-cms";
import { init as initReactAdaptiveSearch } from "@avinet/react-adaptive-search";
import Config from "./config";
import "./index.scss";

const initialState = window.__INITIAL_STATE__;

const store = createStore(initialState);

CmsConfig.init(Config.adaptiveUrl, Config.cmsSiteId);
initReactAdaptiveSearch(Config.adaptiveUrl);
ReactDOM.render(<App store={store} />, document.getElementById("root"));
