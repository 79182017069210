import React from "react";
import PropTypes from "prop-types";
import GeometryCollection from "ol/geom/GeometryCollection";
import Collapsible from "../../component/Collapsible";
import config from "../../config";
import { translate } from "react-translate";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { VectorSource, provideMapState } from "@avinet/react-openlayers";
import { getDistance } from "ol/sphere";
import { Style, Icon } from "ol/style";
import { buffer } from "ol/extent";
import steinsand from "../../static/steinsand.svg";
import konteinerleie from "../../static/container.svg";
import radgjeving from "../../static/info_ikon.svg";
import farligavfall from "../../static/farlig.svg";
import deponi from "../../static/urenemasser.svg";
import recycle from "../../static/recyclewback.svg";

import "./Gjenvinningsstasjon.scss";
import "./AndreTjenester.scss";

const mapStateToProps = state => ({
  records: state.gjenvinningsstasjoner.records,
  loading: state.gjenvinningsstasjoner.loading
});

class AndreTjenester extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closest: undefined
    };
  }

  static propTypes = {
    address: PropTypes.object.isRequired,
    records: PropTypes.array.isRequired,
    loading: PropTypes.bool
  };

  static getDerivedStateFromProps(props, state) {
    if (props.records.length > 0 && !state.closest) {
      let closest = undefined;
      const ap = props.address.olFeature
        .getGeometry()
        .clone()
        .transform(config.mapProjection, "EPSG:4326");
      props.records.forEach(r => {
        if (r.navn !== "Kjevikdalen") {
          return;
        }
        const p = r.olFeature
          .getGeometry()
          .clone()
          .transform(config.mapProjection, "EPSG:4326");
        const newDist = getDistance(p.getCoordinates(), ap.getCoordinates());

        if (!closest || newDist < closest.distance) {
          r.distance = newDist;
          closest = r;
        }
      });
      state.closest = closest;
    }

    if (props.loading) {
      state.closest = undefined;
    }
    return state;
  }

  onExpand = () => {
    const { fitViewAndZoom } = this.props;
    const { closest } = this.state;
    if (closest && closest.id) {
      var extent = buffer(
        new GeometryCollection([
          this.state.closest.olFeature.getGeometry(),
          this.props.address.olFeature.getGeometry()
        ]).getExtent(),
        1.2
      );
      fitViewAndZoom(extent, config.fitViewAndZoom.in);
    }
  };

  onCollapse = () => {
    const { fitViewAndZoom } = this.props;
    fitViewAndZoom(
      this.props.address.olFeature.getGeometry(),
      config.fitViewAndZoom.out
    );
  };

  render() {
    const { t } = this.props;
    const { closest } = this.state;
    return (
      <Collapsible
        className="hushald"
        header={t("header")}
        onCollapse={this.onCollapse}
        onExpand={this.onExpand}
        toggle={
          <VectorSource
            features={closest ? [closest.olFeature] : []}
            layerName="andretjenester-layer"
            key="andretjenester-layer"
            selectable={false}
            style={
              new Style({
                image: new Icon({
                  src: recycle,
                  scale: 1.4
                })
              })
            }
          />
        }
      >
        <div className="andre-tjenester--content">
          <h3>{t("intro")}</h3>
          <div className="content--flexWrapper">
            <div className="andre-tjenester-view--flex-item">
              <Link to="/naering/radgjeving">
                <img src={radgjeving} alt="Farlig avfall" />
                {t("radgjeving")}
              </Link>
            </div>
            <div className="andre-tjenester-view--flex-item">
              <Link to="/leigekonteinar">
                <img src={konteinerleie} alt="Konteinerleie" />
                {t("konteinarleige")}
              </Link>
            </div>
            <div className="andre-tjenester-view--flex-item">
              <Link to="/steinsandoggrus">
                <img src={steinsand} alt="Stein, grus og pukk" />
                {t("steinsandgrus")}
              </Link>
            </div>
            <div className="andre-tjenester-view--flex-item">
              <Link to="/naering/farlegavfall">
                <img src={farligavfall} alt="Farlig avfall" />
                {t("farligavfall")}
              </Link>
            </div>
            <div className="andre-tjenester-view--flex-item">
              <Link to="/naering/deponi">
                <img src={deponi} alt="Deponi" />
                {t("deponi")}
              </Link>
            </div>
          </div>
        </div>
      </Collapsible>
    );
  }
}

export default connect(
  mapStateToProps,
  undefined
)(translate("AndreTjenester")(provideMapState(AndreTjenester)));
