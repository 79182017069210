const validateDatePattern = datestring => {
  const validDatePattern = /^\d{4}-\d{1,2}-\d{1,2}$/; // yyyy.MM.dd
  if (!datestring || !validDatePattern.test(datestring)) {
    return false;
  }
  return true;
};

const isValidDate = datestring => {
  const timestamp = Date.parse(datestring);
  if (isNaN(timestamp)) {
    return false;
  }
  return true;
};

const isWithinYear = datestring => {
  const now = new Date();
  const then = new Date(datestring);
  if (now.getFullYear() === then.getFullYear()) {
    return true;
  }
  return false;
};

const isInFuture = datestring => {
  const now = new Date();
  const then = new Date(datestring);
  if (then > now) {
    return true;
  }
  return false;
};

const formatColumnValue = (value, valueFormatter, dataType, locale) => {
  dataType = getDataTypeName(dataType);

  const dateOpts = {
    day: "numeric",
    month: "numeric",
    year: "numeric"
  };
  const datetimeOpts = {
    hour: "numeric",
    minute: "numeric",
    day: "numeric",
    month: "numeric",
    year: "numeric"
  };
  const timeOpts = {
    hour: "numeric",
    minute: "numeric"
  };

  switch (dataType) {
    case "date":
    case "datetime":
    case "time":
      value = fixDate(value);

      if (!value) {
        return;
      }

      if (dataType === "date") {
        value = value.toLocaleString(locale, dateOpts);
      } else if (dataType === "datetime") {
        value = value.toLocaleString(locale, datetimeOpts);
      } else {
        value = value.toLocaleString(locale, timeOpts);
      }
      break;

    case "decimal":
    case "single":
    case "double":
      value = Number(value).toFixed(2);
      break;

    case "integer":
      value = Number(value).toFixed(0);
      break;

    default:
      break;
  }
  if (!value || !valueFormatter) return value;
  return valueFormatter.replace(/##VALUE##/g, value);
};

const getDataTypeName = dataType => {
  switch (dataType) {
    case "date" || "154627bd-2b5e-4ecb-97ef-c4af7d3e01c6":
      return "date";
    case "time" || "39a354c5-ea82-4bfd-b7db-2628984fcd6e":
      return "time";
    case "datetime" || "2a76ddc3-2d32-4476-82ae-58ce06dd5652":
      return "datetime";
    case "4d2ff201-1757-4133-aeda-ea5217c1d6f6":
      return "boolean";
    case "61980343-edc4-4acc-be16-c313711b9fd4":
      return "decimal";
    case "bc3f2551-d3e0-4a27-831d-6c869ab920f5":
      return "single";
    case "4b2a5e2d-c2ac-4822-b486-07e3989e0eed":
      return "double";
    case "404c8d64-3727-476f-8631-3fbd03ca738b":
      return "integer";
    case "dee25205-d3a3-42a5-870f-8d673491b2cf":
      return "text";
    case "521e4fed-87f5-4cfa-9911-94f98afcb708":
      return "serial";
    case "f37929f7-12b0-46f7-a681-026e6a554cba":
      return "foreig_key";
    case "7989471b-8a83-4501-a9a8-0b9798cc9e53":
      return "uuid";
    case "4b9a1fb5-c35f-472e-a4d7-176f4ceaa290":
      return "list";
    case "3386dc9b-6143-4cba-8c38-d8b4a96d0bdc":
      return "multiselect_list";
    case "41becc03-4c9a-4587-8291-2e50b20e9d5b":
      return "editiable_list";
    case "fcd7139f-06ac-4526-a7a9-48dfe3de3463":
      return "geometry";
    case "2b7e661f-cf07-4fcd-8d44-0d664c819356":
      return "picture";
    case "57afb4a6-384d-46fc-99b3-e1d55c5618fe":
      return "document";
    case "8d1fb2ce-c9b8-4feb-8c58-001738ccc03b":
      return "file";
    case "22f04234-0055-4e74-aa50-98f89cddc080":
      return "rich_text";
    case "63fe55ef-0a0b-47c3-9027-d98ad885d1f1":
      return "text_area";
    case "76c2a3d6-4e46-4d23-b444-256fc2c8bd5a":
      return "media";
    case "92f9c7c3-90b3-41f9-bb4b-e37cc681db68":
      return "url";
    case "bc8e073b-12bd-42c3-b087-1ea3b5472e5f":
      return "url[]";

    default:
      break;
  }
};

const fixDate = date => {
  if (date && typeof date === "string") {
    if (date.indexOf("/Date") === 0) {
      date = date.replace("/Date(", "");
      date = date.replace(")/", "");
      return new Date(parseInt(date, 10) || date);
    } else {
      return new Date(date);
    }
  } else {
    return new Date(date);
  }
};

export {
  validateDatePattern,
  isValidDate,
  isWithinYear,
  isInFuture,
  fixDate,
  formatColumnValue
};
