import React from "react";
import PropTypes from "prop-types";
import GeometryCollection from "ol/geom/GeometryCollection";
import Collapsible from "../../component/Collapsible";
import Loading from "../../component/Loading/Loading";
import Config from "../../config/index";
import {
  scopedBindActionCreators,
  digiThemeActions
} from "react-adaptive-utils";
import { translate } from "react-translate";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { VectorSource, provideMapState } from "@avinet/react-openlayers";
import { buffer } from "ol/extent";
import { Style, Fill, Stroke } from "ol/style";
import { fixDate } from "../../utils/date";
import "./Varsel.scss";

const mapDispatchToProps = dispatch => ({
  varsel: scopedBindActionCreators(
    Object.assign({}, digiThemeActions),
    dispatch,
    "varsel"
  )
});

const mapStateToProps = state => ({
  records: state.varsel.records,
  loading: state.varsel.loading
});

class Varsel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closest: undefined,
      features: [],
      selectedMessageId: null,
      prevMapState: null
    };
  }

  static propTypes = {
    address: PropTypes.object.isRequired,
    records: PropTypes.array.isRequired,
    loading: PropTypes.bool
  };

  componentDidMount = () => {
    var d = new Date();
    d.setDate(d.getDate() - 365);

    let dateString =
      (d.getMonth() < 9 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1) +
      "/" +
      (d.getDate() < 10 ? "0" + d.getDate() : d.getDate()) +
      "/" +
      d.getFullYear();

    let filterObject = {
      filterColumns: [
        {
          name: "geom",
          comparisonOperator: "IS NOT NULL"
        },
        {
          name: "send_date",
          netType: "DateTime",
          logicalOperator: "AND",
          comparisonOperator: ">",
          value: dateString
        },
        {
          name: "statuscode",
          netType: "String",
          logicalOperator: "AND",
          comparisonOperator: "LIKE",
          value: "OK%"
        },
        {
          name: "sent_to_type",
          netType: "String",
          logicalOperator: "AND",
          comparisonOperator: "LIKE",
          value: "person%"
        }
      ]
    };

    if (this.props.location.state.address.geom) {
      filterObject.defaultFilter = [
        {
          comparisonOperator: "ST_INTERSECTS",
          logicalOperator: "AND",
          name: "geom",
          netType: "geometry",
          value: "SRID=4326;" + this.props.location.state.address.geom
        }
      ];
    }

    this.props.varsel.readFiltered(
      Config.themes.varsel.columns,
      filterObject,
      Config.mapProjCode,
      0,
      0
    );
  };

  setFeatures(r) {
    if (r) {
      this.setState({ features: [r.olFeature] });
    } else {
      this.setState({ features: [] });
    }
  }

  onExpand = () => {};

  onCollapse = () => {};

  mapReceipientCodes(codes) {
    let receipientTypes = [];
    if (codes.includes("F")) {
      receipientTypes.push("Hytteeiere");
    }
    if (codes.includes("R")) {
      receipientTypes.push("Huseiere");
    }
    if (codes.includes("B")) {
      receipientTypes.push("Beboere");
    }
    return receipientTypes.join(", ");
  }

  renderVarsel(filteredRecords) {
    const { fitViewAndZoom, setCenter } = this.props;
    return (
      <div className="varsel--flex-wrapper">
        {filteredRecords
          .sort((a, b) => {
            if (a.id > b.id) {
              return -1;
            }
            return 1;
          })
          .map(r => {
            const d = fixDate(r.send_date);
            return (
              <div
                onClick={() => {
                  if (r.id === this.state.selectedMessageId) {
                    if (this.state.prevMapState) {
                      const { center, zoom } = this.state.prevMapState;
                      setCenter(center, zoom);
                    }
                    this.setState({ selectedMessageId: null });
                    this.setFeatures();
                  } else {
                    this.setState({
                      selectedMessageId: r.id,
                      prevMapState: {
                        zoom: this.props.mapZoom,
                        center: this.props.mapCenter
                      }
                    });
                    this.setFeatures(r);

                    var extent = buffer(
                      new GeometryCollection([
                        r.olFeature.getGeometry()
                      ]).getExtent(),
                      1.2
                    );
                    fitViewAndZoom(extent);
                  }
                }}
                key={r.id}
                className={
                  r.id === this.state.selectedMessageId
                    ? "varsel--flex-item active"
                    : "varsel--flex-item"
                }
              >
                <div className="textmessage-meta">
                  <div className="content-block">
                    {this.mapReceipientCodes(r.sent_to)}
                  </div>
                  <div className="content-block">
                    {d.toLocaleDateString("no-NO") +
                      " " +
                      d.toLocaleTimeString("no-NO")}
                  </div>
                </div>
                <div className="textmessage-content">{r.textmessage}</div>
              </div>
            );
          })}
      </div>
    );
  }

  featureStyle = f => {
    return new Style({
      stroke: new Stroke({
        color: "rgba(0, 0, 255, 0.5)",
        width: 2
      }),
      fill: new Fill({
        color: "rgba(0, 0, 255, 0.2)"
      })
    });
  };

  render() {
    const { t, loading, records, location } = this.props;
    const { features } = this.state;

    let searchType = "hytte";
    if (location.pathname.includes("/hushald/")) {
      searchType = "hushald";
    }
    const filteredRecords = records.filter(r => {
      if (!r.sent_to) {
        return false;
      }
      if (searchType === "hytte") {
        return r.sent_to.includes("F");
      }
      return r.sent_to.includes("R") || r.sent_to.includes("B");
    });

    return (
      <Collapsible
        className="hushald"
        header={
          loading ? (
            <React.Fragment>
              {t("header")}
              {loading && <Loading />}{" "}
            </React.Fragment>
          ) : (
            t("header")
          )
        }
        onCollapse={this.onCollapse}
        onExpand={this.onExpand}
        toggle={
          <VectorSource
            features={features}
            layerName="varsel-layer"
            key="varsel-layer"
            selectable={false}
            styleFn={() => this.featureStyle}
          />
        }
      >
        <div className="varsel--content">
          <h3>{t("headingText")}</h3>
          <span>{t("clickToShowInMap")}</span>
          {filteredRecords.length > 0 ? (
            this.renderVarsel(filteredRecords)
          ) : (
            <div className="varsel--no-results">{t("noResults")}</div>
          )}
        </div>
      </Collapsible>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate("Varsel")(provideMapState(withRouter(Varsel))));
