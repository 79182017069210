import React from "react";
import PropTypes from "prop-types";
import WKT from "ol/format/WKT";
import Config from "../../config";
import Loading from "../../component/Loading/Loading";
import Collapsible from "../../component/Collapsible";
import { translate } from "react-translate";
import { VectorSource, provideMapState } from "@avinet/react-openlayers";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  scopedBindActionCreators,
  digiThemeActions
} from "react-adaptive-utils";

import "./Konteinerleie.scss";

const mapStateToProps = state => ({
  records: state.konteinerleie.records,
  loading: state.konteinerleie.loading
});

const mapDispatchToProps = dispatch =>
  scopedBindActionCreators(
    Object.assign({}, digiThemeActions),
    dispatch,
    "konteinerleie"
  );

class Konteinerleie extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  static propTypes = {
    address: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.readKonteinerLeie();
  }

  readKonteinerLeie() {
    const { address } = this.props;
    const filter = Config.themes.konteinerleie.intersectFilter;
    filter.value =
      "SRID=" +
      Config.mapProjCode +
      ";" +
      new WKT().writeFeature(address.olFeature);

    this.props.readFiltered(
      Config.themes.konteinerleie.columns,
      {
        filterColumns: [filter]
      },
      Config.mapProjCode,
      0,
      1
    );
  }

  static getDerivedStateFromProps(props, state) {
    if (props.records.length > 0 && !state.olFeature) {
      state.olFeature = new WKT().readFeature(props.records[0].geom_wkt);
    }
    if (props.loading) {
      state.olFeature = undefined;
    }
    return state;
  }

  onExpand = () => {
    const { fitViewAndZoom } = this.props;
    const { olFeature } = this.state;
    if (olFeature)
      fitViewAndZoom(olFeature.getGeometry(), Config.fitViewAndZoom.in);
  };

  onCollapse = () => {
    const { fitViewAndZoom } = this.props;
    fitViewAndZoom(
      this.props.address.olFeature.getGeometry(),
      Config.fitViewAndZoom.out
    );
  };

  render() {
    const { t, records, loading } = this.props;
    const { olFeature } = this.state;
    return (
      <Collapsible
        className="hushald"
        onCollapse={this.onCollapse}
        onExpand={this.onExpand}
        header={
          records.length === 0 ? (
            <React.Fragment>
              <span>{t("konteinarleigeOgTransport")}</span>
              {loading && <Loading />}
            </React.Fragment>
          ) : (
            t("konteinarleigeOgTransport")
          )
        }
        toggle={
          <VectorSource
            features={olFeature ? [olFeature] : []}
            layerName="konteinerleie-layer"
            key="konteinerleie-layer"
            selectable={false}
          />
        }
      >
        {records.length > 0 ? (
          <div className="konteinerleie--content">
            <h3>{records[0].sone}</h3>
            <h4>{t("pris")}</h4>
            <p>
              <span>1 - 10 m3:</span>
              <span>{records[0].pristilti}</span>
            </p>
            <p>
              <span>11 - 45 m3:</span>
              <span>{records[0].pristilfortifem}</span>
            </p>
            <Link to="/leigekonteinar">{t("sjaMerOm")}</Link>
          </div>
        ) : (
          !loading &&
          records.length === 0 && (
            <div className="konteinerleie--no-results">{t("noResults")}</div>
          )
        )}
      </Collapsible>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(provideMapState(translate("Konteinerleie")(Konteinerleie)));
