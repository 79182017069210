import { combineReducers } from "redux";
import { navReducer, pageReducer } from "react-adaptive-cms";
import { mapHistoryReducer, digiThemeReducer } from "react-adaptive-utils";
import Config from "../config";

export const makeRootReducer = asyncReducers => {
  return combineReducers({
    page: pageReducer,
    nav: navReducer,
    mapHistory: mapHistoryReducer,
    gjenvinningsstasjoner: digiThemeReducer(
      "gjenvinningsstasjoner",
      Config.ngirkartUrl,
      Config.themes.gjenvinningsstasjoner.uuid
    ),
    varsel: digiThemeReducer(
      "varsel",
      Config.ngirkartUrl,
      Config.themes.varsel.uuid
    ),
    hentedag: digiThemeReducer(
      "hentedag",
      Config.ngirkartUrl,
      Config.themes.hentedag.uuid
    ),
    konteinerleie: digiThemeReducer(
      "konteinerleie",
      Config.ngirkartUrl,
      Config.themes.konteinerleie.uuid
    ),
    glasskonteinere: digiThemeReducer(
      "glasskonteinere",
      Config.ngirkartUrl,
      Config.themes.glasskonteinere.uuid
    ),
    varhaustrydding: digiThemeReducer(
      "varhaustrydding",
      Config.adaptiveUrl,
      Config.themes.varhaustrydding.uuid
    ),
    hyttekonteinarar: digiThemeReducer(
      "hyttekonteinarar",
      Config.ngirkartUrl,
      Config.themes.hyttekonteinarar.uuid
    ),
    aktuelt: digiThemeReducer(
      "aktuelt",
      Config.adaptiveUrl,
      Config.themes.aktuelt.uuid
    ),
    nyheter: digiThemeReducer(
      "nyheter",
      Config.adaptiveUrl,
      Config.themes.nyheter.uuid
    ),
    eternit: digiThemeReducer(
      "eternit",
      Config.ngirkartUrl,
      Config.themes.eternit.uuid
    ),
    kommuneareal: digiThemeReducer(
      "kommuneareal",
      Config.ngirkartUrl,
      Config.themes.kommuneareal.uuid
    ),
    ...asyncReducers
  });
};

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;

  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
